<template>
  <input
    :class="$style.input"
    @change="onChange"
    ref="file"
    type="file"
    :accept="accept"
  />
</template>

<script>
export default {
  name: 'UiFile',
  props: {
    png: {
      type: Boolean,
      default: true
    },
    jpg: {
      type: Boolean,
      default: true
    },
    mp4: {
      type: Boolean,
      default: false
    },
    size: {
      type: Number,
      default: 524288 // 512кб
    }
  },
  emits: ['error', 'input'],
  computed: {
    accept() {
      const accept = []
      if (this.jpg) {
        accept.push('image/jpeg', 'image/jpg')
      }
      if (this.png) {
        accept.push('image/png')
      }
      if (this.mp4) {
        accept.push('video/mp4')
      }
      return accept.join(',')
    }
  },
  methods: {
    clear() {
      this.$refs.file.value = null
    },
    onChange() {
      const file = this.$refs.file.files[0]
      if (file) {
        if (file.size > this.size) {
          this.$emit('error', `Max size ${this.size / 1000000}mb`)
        } else {
          this.$emit('input', file)
        }
        setTimeout(() => this.clear(), 100)
      }
    },
    select() {
      this.$refs.file.click()
    }
  }
}
</script>

<style lang="scss" module>
.input {
}
</style>
